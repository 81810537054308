// React
import React from 'react'

// Gatsby
import {
    Link,
    graphql
} from "gatsby"

// Site components
import Layout from '../components/layout';
import SEO from "../components/seo"

// Styles
import "../styles/pages/TagsListPage.scss"

// Tags page component
// (Lists all tags)
function TagsPage({
    data,
    location
}) {

    // Tags list
    const tags = data.allMdx.group

    // Page frontmatter
    const pageTitle = 'Blog topics'
    const pageCategory = 'Game Development'
    const pageDescription = 'Topics in game development.';

    // Render
    return (
        <Layout>

            {/* SEO */}
            <SEO
                title={pageTitle}
                description={pageDescription}
                pathname={location.pathname}
            />  

            {/* Page */}
            <article id="tags-page">

                {/* Page header */}
                <header className="page-header">
                    
                    {/* Text on lhs */}
                    <section className="header-text">

                        {/* Page category */}
                        <div className="page-category">
                            {pageCategory}
                        </div>

                        {/* Page title */}
                        <h1 className="page-title">
                            {pageTitle}
                        </h1>

                        {/* Page description */}
                        <p className="page-description">
                            {pageDescription}
                        </p>

                    </section>
                    
                    {/* Buttons on top-right */}
                    <aside className="page-buttons">
                        <Link to={'/blog'}>
                            All Posts
                        </Link>
                    </aside>

                </header>

                {/* Page content */}
                <main className="page-main">

                    <div className="tags-list">
                        { tags.map(tag => (
                            <Link
                                className="tag"
                                to={`/tags/${tag.fieldValue}`}
                                key={tag.fieldValue}
                            >
                                <span className="name">
                                    {tag.fieldValue}
                                </span>

                                <span className="count">
                                    {`(${tag.totalCount})`} 
                                    {/* { tag.totalCount > 1 
                                        ? ' posts)' : 
                                        ' post)' 
                                    } */}
                                </span>
                            </Link>
                        ))}
                    </div>
                
                </main>

            </article>

        </Layout>
    )

}

// Export component
export default TagsPage;

// GraphQL query
export const pageQuery = graphql`

  query {
    allMdx(
      limit: 1000
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }

  }

`